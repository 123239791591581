/*
 * 업무구분: 이달의업적
 * 화 면 명: MSPAP106P
 * 화면설명: 이달의업적 FC 선택화면
 * 접근권한: 지점장
 * 작 성 일: 2022.12.26
 * 작 성 자: 최준석
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="상세조회" type="subpage" :topButton="false" @on-header-left-click="fn_Cancel">
    <!-- Content영역 -->

      <!-- 25.02.19 개발 -->
      <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area pt30 pb0">
        <SearchCondOrgAP ref="refSearchOrg" v-model="lv_SelectOrg" :pOrgData="pOrgData106P" :isShowCnslt="lv_IsShowCnslt" @selectResult="fn_SlectCnsltResultAP"/>
        <!-- <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultAP"/> -->
      </ur-box-container>

    <!-- Content영역 -->

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button olor="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
        <mo-button olor="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import SearchCondOrgAP from '@/ui/ap/SearchCondOrgAP.vue'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    // 현재 화면명
    name: "MSPAP106P",
    // 현재 화면 ID
    screenId: "MSPAP106P",
    // 컴포넌트 선언
    components: {
      SearchCondOrgAP
    },
    props: {
      pPage: '',
      pFlag: '',
      pData: '',
      fcDtlData: ''
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_IsShowCnslt: true,
        lv_SelectOrg: {},
        pOrgData106P: {},
      }
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_init()

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Cancel)
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_Cancel)
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * @function fn_Init
       * @notice   화면진입시, 최초 실행 함수
       *           초기 셋팅 및 설정
      *********************************************************/
      fn_init() {
        // props 데이터 및 공통코드 설정
        let pOrgData = {}
        if (!_.isEmpty(this.fcDtlData)) {
          pOrgData = this.fcDtlData
          pOrgData.cnslt = {key: this.pData.key, label: this.pData.label}
          this.pOrgData106P = pOrgData
        }

        // 세무팀장 활동확인 메뉴는 담당컨설턴트 조회 불가
        if (this.$props.pPage === 'MSPAP610M') {
          this.lv_IsShowCnslt = false
        }


      },
      /******************************************************************************
       * @function  fn_SlectCnsltResultCM()
       * @notice    조직 변경시, 전역변수에 셋팅
       *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
       * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
       * allChk: 조회버튼 활성화/비활성화 여부
      ******************************************************************************/
      fn_SlectCnsltResultAP (pData) {
        this.lv_SelectOrg = pData
      },
      
      /******************************************************************************
      * Function명 : fn_Clear
      * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
      ******************************************************************************/
      fn_Clear () {
        this.pOrgData106P = {}
      },
      /******************************************************************************
      * Function명 : fn_Search
      * 설명       : 조회 후 팝업 닫음
      ******************************************************************************/
      fn_Search() {
        // this.$emit('onPopupSearch', { rtnData: this.lv_ReturnVal, srchOpt: this.lv_ReturnStr, isInit: this.isInit });
        // pItem, pData

        if (_.isEmpty(this.lv_SelectOrg.cnslt) || this.lv_SelectOrg.cnslt.key === '0') {
          this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
          return
        }

        console.log('lv_SelectOrg>> ', this.lv_SelectOrg)
        let searchOrg = {
            pItem: this.lv_SelectOrg.cnslt,
            pData: {
              hofOrg: this.lv_SelectOrg.hofOrg,
              dofOrg: this.lv_SelectOrg.dofOrg,
              fofOrg: this.lv_SelectOrg.fofOrg
            }
          }
        if (false){
          }
        this.$emit('onPopupSearch', searchOrg.pItem, searchOrg.pData )


        
        // this.$emit('onPopupClose')
      },
      fn_Cancel() {
        this.$emit('onPopupClose')
      }
    }

  }
</script>
<style scoped>
</style>